import { FaCloudDownloadAlt, FaCss3, FaFighterJet, FaFileImage, FaHtml5, FaLink, FaThumbsUp } from 'react-icons/fa'

import Box from '../components/sections/Box'
import Button from '@material-ui/core/Button'
import Column from '../components/section-components/Column'
import Columns from '../components/sections/Columns'
import Head from '../components/informational/Head'
import Hero from '../components/sections/Hero'
import { Link } from 'gatsby'
import Logo from '../components/informational/Logo'
import PropTypes from 'prop-types'
import React from 'react'
import ShareWidget from '../components/informational/ShareWidget'
import StartDownloadForm from '../components/controls/StartDownloadForm'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  /* Ugly fix for misplaced DownloadForm label */
  '@global': {
    '@media only screen and (min-width: 960px)': {
      'form > div > div > fieldset > legend': {
        width: '80% !important',
        marginLeft: '-20%'
      }
    }
  },
  fullWidth: theme.mixins.fullWidth,
  seoStrong: theme.mixins.seoStrong
})

const Component = ({ classes, data }) => (
  <>
    <Head title='Wayback Machine Downloader | Archive.org Downloader' description='Use Wayback Machine Downloader to download any website from the Internet Archive / Wayback Machine. Nothing to install or configure, Download a Website Now!' landinPage />
    <ShareWidget />

    <Hero icon={<Logo frontPage />} title='Wayback Machine Downloader' subTitle='Download the source code and assets from Wayback Machine' wayback>
      <StartDownloadForm index={0} autoFocus wayback />
    </Hero>
    <Columns title='Key Benefits' subTitle={<>Easiest way to <strong className={classes.seoStrong}>download from archive.org, which is also known as wayback machine</strong></>} perrow={3}>
      <Column icon={<FaCloudDownloadAlt />} title='Platform Independent'>
        The web based interface enables you to use <strong className={classes.seoStrong}>wayback machine downloader</strong> straight in your browser on any operating system and without downloading or configuring any software.
      </Column>
      <Column icon={<FaFighterJet />} title='Fast Previews'>
        Wayback machine downloader offers a fast preview of the download result hosted on our servers without consuming your computer's precious disk space.
      </Column>
      <Column icon={<FaThumbsUp />} title='Simplicity'>
        Wayback machine downloader is super simple and easy to use, yet it comes with advanced functionality such as only downloading a subdirectory or certain pages from a website (as a <strong className={classes.seoStrong}>web page downloader</strong>).
      </Column>
    </Columns>
    <Columns title='Wayback Machine Downloader Features' subTitle={<>Wayback Machine Downloader allows you to download websites from archive.org to your local hard drive on your own computer. Wayback Downloader arranges the downloaded site by the original websites relative link-structure. The downloaded website can be browsed by opening one of the HTML pages in a browser.</>} perrow={4}>
      <Column icon={<FaHtml5 />} title='HTML'>
        <strong className={classes.seoStrong}>Wayback Downloader</strong> takes each HTML file and downloads and clones it to your local hard drive.
      </Column>
      <Column icon={<FaLink />} title='Link Conversion'>
        We convert all the links in the HTML files so that they work locally, off-line, instead of pointing to an online website.
      </Column>
      <Column icon={<FaCss3 />} title='CSS &amp; JavaScript'>
        CSS and JavaScript files will be downloaded under a simple folder structure and referenced correctly in the HTML files.
      </Column>
      <Column icon={<FaFileImage />} title='Images'>
        Like other assets, also images will downloaded and referenced locally.
      </Column>
    </Columns>
    <Box title='Ready to Start Using Wayback Machine Downloader?' wayback>
      <StartDownloadForm index={1} wayback />
      <Typography variant='body1' align='center' className={classes.subSection} gutterBottom>
        <Button color='primary' component={Link} to='/' title='Website Downloader'>Use Website Downloader</Button> | <Button color='primary' component={Link} to='/blog/' title='The Website Downloader Blog'>Read our Blog</Button> | <Button color='primary' component={Link} to='/privacy/' title='Privacy Policy'>Privacy</Button> | <Button color='primary' component={Link} to='/terms/' title='Terms of Service'>Terms</Button> | <Button color='primary' component={Link} to='/cookies/' title='Cookie Policy'>Cookies</Button>
      </Typography>
    </Box>
  </>
)

export default withStyles(styles)(Component)

Component.propTypes = {
  classes: PropTypes.object.isRequired
}
